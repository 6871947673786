/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import MUIDataTable from "mui-datatables";
import React from "react";
import { useTranslation } from "react-i18next";
import { TransactionApi } from "services/api";

const ConsentComponent: React.FC = (): JSX.Element => {

  const {t} = useTranslation();
  const TITLE = t("consents");
  const { getTransactions } = TransactionApi();
//   const data = getTransactions();
  const data = [];

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[]}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsentComponent;
