import { RestClient } from '..';
import { API } from './utils';

export const TripClient = (): any => {
  const { get, patch } = RestClient();
  const getTripAll = () => get({url: API + 'trip'});
  const getPaginatedTrips = (page: number, pageSize: number, shifterId?: any, params?: any) => get({url: API +
    `trip?pageNumber=${page}&pageSize=${pageSize}` +
    `${shifterId ? '&shifterId=' + shifterId : ''}` +
    `${params?.transportType > -1 ? '&transportType=' + params.transportType : ''}` +
    `${params?.actual ? '&isActual=' + params.actual : ''}` +
    `${params?.paymentStatus > -1 ? '&offerPaymentStatus=' + params.paymentStatus : ''}` +
    `${params?.createdOnFrom ? '&createdOnFrom=' + params.createdOnFrom : ''}` +
    `${params?.createdOnTo ? '&createdOnTo=' + params.createdOnTo : ''}` +
    `${params?.startDateFrom ? '&startDateFrom=' + params.startDateFrom : ''}` +
    `${params?.startDateTo ? '&startDateTo=' + params.startDateTo : ''}` +
    `${params?.endDateFrom ? '&endDateFrom=' + params.endDateFrom : ''}` +
    `${params?.endDateTo ? '&endDateTo=' + params.endDateTo : ''}` +
    `${params?.fromPlaceId ? '&cityFromPlaceId=' + params.fromPlaceId : ''}` +
    `${params?.toPlaceId ? '&cityToPlaceId=' + params.toPlaceId : ''}` +
    `${params?.phoneNumber ? '&phoneNumber=' + params.phoneNumber : ''}`
  });
  const getTripInventoryById = (tripId: number) => get({url: API + `trip/inventory?tripId=${tripId}`});
  const changeActualTrip = (data: any) => patch({url: API + `trip/actual/change?tripId=${data.tripId}&isActual=${data.isActual}`});
  const getTripById = (tripId: number) => get({url: API + `trip/${tripId}`});
  const getReviewsByTripId = (tripId: number) => get({url: API + `rating?tripId=${tripId}`});

  return {
    getTripAll,
    getPaginatedTrips,
    changeActualTrip,
    getTripById,
    getTripInventoryById,
    getReviewsByTripId
  };
};

export const ParcelClient = (): any => {
  const { get, patch } = RestClient();
  const getParcelAll = () => get({url: API + 'parcel'});
  const getPaginatedParcels = (page: number, pageSize: number, clientId?: any, params?: any) => get({url: API +
    `parcel?pageNumber=${page}&pageSize=${pageSize}` +
    `${clientId ? '&clientId=' + clientId : ''}` +
    `${params?.isActual ? '&isActual=' + params.isActual : ''}` +
    `${params?.parcelSumFrom ? '&parcelSumFrom=' + params.parcelSumFrom : ''}` +
    `${params?.parcelSumTo ? '&parcelSumTo=' + params.parcelSumTo : ''}` +
    `${params?.createdOnFrom ? '&createdOnFrom=' + params.createdOnFrom : ''}` +
    `${params?.createdOnTo ? '&createdOnTo=' + params.createdOnTo : ''}` +
    `${params?.departureDateTimeFrom ? '&departureDateTimeFrom=' + params.departureDateTimeFrom : ''}` +
    `${params?.departureDateTimeTo ? '&departureDateTimeTo=' + params.departureDateTimeTo : ''}` +
    `${params?.fromPlaceId ? '&cityFromPlaceId=' + params.fromPlaceId : ''}` +
    `${params?.toPlaceId ? '&cityToPlaceId=' + params.toPlaceId : ''}` +
    `${params?.deliveryStatus ? '&parcelDeliveryStatus=' + params.deliveryStatus : ''}` +
    `${params?.baggageTypes ? '&baggageTypes=' + params.baggageTypes : ''}` +
    `${params?.phoneNumber ? '&phoneNumber=' + params.phoneNumber : ''}`
  });
  const getParcelInventoryById = (dealId: string) => get({url: API + `parcel/inventory?dealId=${dealId}`});
  const getBaggages = () => get({url: API + `type/baggage?lang=${localStorage.getItem('language') ?? 'ru'}`});
  const changeActualParcel = (data: any) => patch({url: API + `parcel/actual/change?parcelId=${data.parcelId}&isActual=${data.isActual}`});
  const changeDeliveryStatus = (data: any) => patch({url: API + `parcel/deliveryStatus?parcelId=${data.parcelId}&status=${data.status}`});
  const getOffersByParcelId = (data: any) => get({url: API + `parcel/getOffers/${data.parcelId}?status=0`});
  const getReviewsByParcelId = (parcelId: string) => get({url: API +`rating?parcelId=${parcelId}`});
  const getParcelById = (parcelId: number) => get({url: API + `parcel/${parcelId}`});

  return {
    getParcelAll,
    getPaginatedParcels,
    getBaggages,
    changeActualParcel,
    getParcelInventoryById,
    changeDeliveryStatus,
    getOffersByParcelId,
    getReviewsByParcelId,
    getParcelById
  };
};

export const PayoutClient = (): any => {
  const { get, post } = RestClient();
  const getPayouts = () => get({url: API + 'parcel/payouts'});
  const verifyPayout = (body: any) => post({url: API + `parcel/payout`, body: body});

  return {
    getPayouts,
    verifyPayout
  };
};

export const CarsClient = (): any => {
  const { get, deleteRequest } = RestClient();
  const getPaginatedCars = (page: number, pageSize: number, clientId?:string, params?: any) => get({url: API +
    `car?page=${page}&pageSize=${pageSize}` +
    `${clientId ? '&clientId=' + clientId : ''}` +
    `${params?.brand ? '&brand=' + params.brand : ''}` +
    `${params?.model ? '&model=' + params.model : ''}` +
    `${params?.createdOnFrom ? '&createdOnFrom=' + params.createdOnFrom : ''}` +
    `${params?.createdOnTo ? '&createdOnTo=' + params.createdOnTo : ''}` +
    `${params?.verificationStatus ? '&verificationStatus=' + params.verificationStatus : ''}` +
    `${params?.clientFullName ? '&clientFullName=' + params.clientFullName : ''}`
  });
  const getCarById = (id: string) => get({url: API + `car/${id}`});
  const deleteCarById = (id: string) => deleteRequest({url: API + `car?carId=${id}`});
  const getDealByCarId = (id: string) => get({url: API + `deal/by-car?carId=${id}`});

  return {
    getPaginatedCars,
    getCarById,
    deleteCarById,
    getDealByCarId
  };
};

export const Verification = (): any => {
  const { get, patch, deleteRequest } = RestClient();
  const getVerificationDeal = (page: number, pageSize: number, params?: any) => get({url: API +
    `verification/deal?page=${page}&pageSize=${pageSize}` +
    `${params?.statusCode > -1 ? '&verificationStatus=' + params.statusCode : ''}`
  });
  const getVerificationDealById = (id: number) => get({url: API + `verification/deal/${id}`});
  const changeVerificationDealById = (body: any) => patch({ url: API + `verification/deal/`, body: body});
  const getVerificationUser = () => get({url: API + 'user-verification'});
  const getVerificationCar = (page: number, pageSize: number, params?: any) => get({url: API +
    `verification/car?page=${page}&pageSize=${pageSize}` +
    `${params?.statusCode > -1 ? '&verificationStatus=' + params.statusCode : ''}`
  });
  const getVerificationUserById = (id: number) => get({url: API + `user-verification/${id}`});
  const changeVerificationUserById = (body: any) => patch({url: API + `user-verification`, body: body});
  const deleteVerificationUserById = (id: number) => deleteRequest({url: API + `user-verification/${id}`});
  const getVerificationCarById = (id: number) => get({url: API + `verification/car/${id}`});
  const changeVerificationCarById = (body: any) => patch({url: API + `verification/car/`, body: body});

  return {
    getVerificationDeal,
    getVerificationDealById,
    changeVerificationDealById,
    getVerificationUser,
    getVerificationCar,
    getVerificationUserById,
    changeVerificationUserById,
    getVerificationCarById,
    changeVerificationCarById,
    deleteVerificationUserById
  };
};

export const BlackList = (): any => {
  const { get, post, deleteRequest } = RestClient();
  const getBlackList = () => get({url: API + `black-list`});
  const getBlackListById = (id: string) => get({url: API + `black-list/${id}`});
  const addUserToBlackList = (data: any) => post({url: API + `black-list/append-user`, body: data});
  const deleteBlackListById = (id: string) => deleteRequest({url: API + `black-list?userId=${id}`});

  return {
    getBlackList,
    getBlackListById,
    addUserToBlackList,
    deleteBlackListById
  };
};

export const UserClient = (): any => {
  const { get, patch, deleteRequest } = RestClient();
  const getUserAll = () => get({url: API + 'user'});
  const getUserById = (id: string) => get({url: API + `user/${id}`});
  const getPaginatedUsers = (page: number, pageSize: number, params?: any) => get({url: API +
    `user?page=${page}&pageSize=${pageSize}` +
    `${params?.phoneNumber ? '&phoneNumber=' + params.phoneNumber : ''}` +
    `${params?.userType ? '&userType=' + params.userType : ''}` +
    `${params?.firstName ? '&firstName=' + params.firstName : ''}` +
    `${params?.secondName ? '&secondName=' + params.secondName : ''}` +
    `${params?.thirdName ? '&thirdName=' + params.thirdName : ''}` +
    `${params?.email ? '&email=' + params.email : ''}` +
    `${params?.dateOfBirthFrom ? '&dateOfBirthFrom=' + params.dateOfBirthFrom : ''}` +
    `${params?.dateOfBirthTo ? '&dateOfBirthTo=' + params.dateOfBirthTo : ''}` +
    `${params?.createdOnFrom ? '&createdOnFrom=' + params.createdOnFrom : ''}` +
    `${params?.createdOnTo ? '&createdOnTo=' + params.createdOnTo : ''}`
  });
  const changeUserDetail = (id: number, data: any) => patch({url: API + `user/${id}`, body: data});
  const findFilesOfUserById = (id: string) => get({url: API + `profile/findFilesOfUser/${id}`});
  const getUserAvatarById = (id: string) => get({url: API + `profile/avatar/${id}`});
  const getRatingByUser = (id: string) => get({url: API + `rating/by-user?userId=${id}`});
  const removeUserById = (userId: number) => deleteRequest({url: API + `user/delete/admin/${userId}`, body: {reason: 'reason'}});
  const changeUserRating = (id: string, body: any) => patch({url: API + `user/rating/${id}`, body: body});

  return {
    getUserAll,
    getUserById,
    getPaginatedUsers,
    changeUserDetail,
    findFilesOfUserById,
    getUserAvatarById,
    getRatingByUser,
    removeUserById,
    changeUserRating
  };
};

export const RatingApi = (): any => {
  const { get, put, deleteRequest } = RestClient();
  const getRatingAll = () => get({url: API + 'rating'});
  const getPaginatedRatings = (page: number, pageSize: number, toUserId?: string, params?: any) => get({url: API +
    `rating?page=${page}&pageSize=${pageSize}` +
    `${toUserId ? '&toUserId=' + toUserId : ''}` +
    `${params?.description ? '&description=' + params.description : ''}` +
    `${params?.rate ? '&rate=' + params.rate : ''}` +
    `${params?.createdOnFrom ? '&createdOnFrom=' + params.createdOnFrom : ''}` +
    `${params?.createdOnTo ? '&createdOnTo=' + params.createdOnTo : ''}` +
    `${params?.fromUser ? '&fromUser=' + params.fromUser : ''}` +
    `${params?.toUser ? '&toUser=' + params.toUser : ''}`
  });
  const changeRating = (data: any) => put({url: API + 'rating', body: data});
  const deleteRating = (id: string) => deleteRequest({url: API + `rating?ratingId=${id}`});

  return {
    getRatingAll,
    getPaginatedRatings,
    changeRating,
    deleteRating
  };
};

export const PopularCities = (): any => {
  const { get, post, deleteRequest, patch } = RestClient();
  const getPopularCitiesAll = () => get({url: API + 'popular-city'});
  const createPopularCity = (body: {placeId: string; imageUrl: string}) => post({url: API + 'popular-city', body: body});
  const deletePopularCityById = (id: number) => deleteRequest({url: API + `popular-city/${id}`});
  const changeImage = (body: {id: number; imageUrl: string}) => patch({url: API + `popular-city/image-url/change`, body: body});

  return {
    getPopularCitiesAll,
    createPopularCity,
    deletePopularCityById,
    changeImage
  };
};

export const ProfileClient = (): any => {
  const { get, patch } = RestClient();
  const getProfileDefaults = () => get({url: API + `profile`});
  const changeProfileCurrency = (body: {id: string; currency: number}) => patch({url: API + `profile`, body});

  return {
    getProfileDefaults,
    changeProfileCurrency
  };
};

export const TransactionApi = (): any => {
  const { get, patch } = RestClient();
  const getTransactions = () => get({url: API + `transaction`});
  const changeSmtng = (body: {id: string; currency: number}) => patch({url: API + `transaction`, body});

  return {
    getTransactions,
    changeSmtng
  };
};

export const PlaceDetails = (): any => {
  const getPlaceDetails = (placeId: string) => {
    const stored = localStorage.getItem(placeId);
    const lang = localStorage.getItem('language') ?? 'ru';
    const parsedObj = stored ? JSON.parse(stored) : {};
    if (parsedObj[lang]) return Promise.resolve(parsedObj[lang]);
    else {
      return new Promise((resolve, reject) => {
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        const request = {
          placeId,
          fields: ["name", "formatted_address", "place_id"],
          language: lang
        }
        service.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            resolve({
              name: place.name,
              placeId: place.place_id,
              fullAddress: place.formatted_address
            });
          } else if (status === google.maps.places.PlacesServiceStatus.INVALID_REQUEST) {
            resolve({
              name: 'unknown',
              placeId: undefined,
              fullAddress: 'unknown address'
            })
          } else {
            reject(new Error(`Error fetching place details: ${status}`));
          }
        });
      }).then(res => {
        localStorage.setItem(placeId, JSON.stringify({...parsedObj, [lang]: res}));
        return res;
      });
    };
  };

  return {
    getPlaceDetails
  }
}
