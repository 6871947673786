/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";

const containerStyle = {
  width: '100%',
  height: '750px'
};

const center = {
  lat: 40.7929,
  lng: 43.8465
};

const GoogleMapsComponent: React.FC = (): JSX.Element => {

  const {t} = useTranslation();
  const TITLE = t("google_maps");

  return (
    <div>
      <h1>{TITLE}</h1>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          // onLoad={onLoad}
        >
          <Marker position={center} />
        </GoogleMap>
    </div>
  );
}

export default GoogleMapsComponent;
