import moment from 'moment';
import { returnBaggageType } from '../../models/util';

export const COLUMN = (t: any, carId?: string) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({index}) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({index}) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <div style={{flexDirection:'row',display:'flex',alignItems:"center"}}>
          <p>{value}</p>
          <>
            {tableMeta.rowIndex === 0 && value === 'Car' &&
              <a href={`/cars/details/${carId}`} style={{marginLeft:'10px'}} target="_blank" rel="noreferrer">{t('look_detail')}</a>
            }
          </>
        </div>
      )
    }
  }
];
export const createOffers = (offers: any, t: any, currency: string) => {
  return offers?.map((offer) => {
    return {
      fullUserName: offer.fullUserName,
      description: offer.description,
      offerStatus: offer.offerStatus,
      offerSum: `${offer.offerSum} ${currency}`,
      payment:
        offer?.paymentStatus &&
        offer?.paymentStatus.includes('Paid')
          ? t('done')
          : t('not_done'),
      phoneNumber: offer.phoneNumber
    };
  });
};
export const COMLUMNOFFERS = (t?: any) => [
  {
    name: 'fullUserName',
    label: t('fio')
  },
  {
    name: 'description',
    label: t('description')
  },
  {
    name: 'offerStatus',
    label: t('offer_status')
  },
  {
    name: 'offerSum',
    label: t('sum')
  },
  {
    name: 'payment',
    label: t('payment')
  },
  {
    name: 'phone',
    label: t('number_phone')
  }
];
export const labels = [
  'Телефонный номер',
  'Фамилия',
  'Имя пользователя',
  'Отчество',
  'Дата рождения',
  'Эл. адрес',
  'Дата регистрации'
];

export const createTripDetail = (
  detail: any,
  baggages: any,
  t: any
): { name: string; data: any }[] => {
  return [
    {
      name: t('title'),
      data: detail.tripTitle
    },
    {
      name: t('date_of_creation'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('fio_carrier'),
      data: detail.shifterFullName
    },
    {
      name: t('number_carrier'),
      data: detail.shifterPhoneNumber
    },
    {
      name: t('travel_start_date'),
      data: moment(detail.startDateTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('travel_end_date'),
      data: moment(detail.endDateTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('address_from'),
      data: detail.addressFrom
    },
    {
      name: t('address_to'),
      data: detail.addressTo
    },

    {
      name: t('type_transport'),
      data: detail.transportTypes.map(
        (transport: string, inx: number) => {
          let str = ''
          str += t(transport.toLowerCase()) + (detail.transportTypes.length - 1 === inx ? '' : ', ');
          return str;
        }
      )
    },
    {
      name: t('sum'),
      data: detail.price
    },
    {
      name: t('baggage_type'),
      data: detail?.baggageType?.map(
        (item: string, index: number) =>
          returnBaggageType(item, baggages, index) +
          (detail?.baggageType.length - 1 === index ? '' : ', ')
      )
    },
    {
      name: t('description'),
      data: detail.tripDescription
    },
    {
      name: t('grade'),
      data: detail.isRated ? t('yes') : t('no')
    },
    {
      name: t('verificationStatus'),
      data: detail.verificationStatus
    },
    
  ];
};
export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
export const COLUMN_POINTS=(t:any)=>[
  {
    label:t('industrialPoint'),
    name:'fullAddress'
  }
]
export const GET_COLUMN = (t: any) => [
  {
    name: 'fullUserName',
    label: t('fio_sender')
  },
  {
    name: 'clientId',
    label: t('id_sender'),
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <p
              onClick={() => {
                window.open(`/user/details/${value}`, '_blank');
              }}>
              {value}
            </p>
          </div>
        );
      }
    }
  },
  {
    name: 'id',
    label: t('placeId_sender'),
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <p
              onClick={() => {
                window.open(`/parcel/details/${value}`, '_blank');
              }}>
              {value}
            </p>
          </div>
        );
      }
    }
  },
  {
    name: 'phone',
    label: t('number_phone_sender')
  }
];
