import moment from 'moment';
import { returnBaggageType } from '../util';
import TripModel from '../trip';

type tripResponseModel = {
  addressFrom: any;
  addressTo: any;
  baggageType: any;
  createdOnTS: string;
  tripDescription: string;
  tripStatus: string;
  tripType: string;
  endDateTS: string;
  id: number;
  lastModified: string;
  locationFrom: any;
  locationTo: any;
  offers: Array<any>;
  shifterFullName: string;
  shifterId: string;
  shifterPhoneNumber: string;
  startDateTS: string;
  transportTypes: Array<string>;
  transportationPrice: number;
  wayPointCollection: Array<any>;
  isActual: boolean,
  fromPlaceId: string,
  toPlaceId: string,
  tripTitle: string,
  currency: string
};

const createTripFromRowData = (item: tripResponseModel, bagges): TripModel =>
  new TripModel({
    tripTitle: item.tripTitle,
    createdOn: moment(item.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    id: item.id,
    fio: item?.shifterFullName,
    phoneNumber: item?.shifterPhoneNumber,
    startDate: moment(item.startDateTS).format('DD.MM.YYYY HH:mm').toString(),
    endDate: moment(item.endDateTS).format('DD.MM.YYYY HH:mm').toString(),
    addressFrom: item.addressFrom?.city,
    addressTo: item.addressTo?.city,
    transportTypes: item.transportTypes,
    sum: `${item.transportationPrice??''} ${item.currency??''}`,
    baggageType: item?.baggageType?.map((item: string, index: number) =>
      returnBaggageType(item, bagges, index)
    ),
    tripStatus: item?.tripStatus,
    tripType: item?.tripType,
    offerSum: item.offers,
    offerFio: item.offers,
    payment: item.offers,
    unvisable: item.id,
    phoneNumberOffer: item.offers,
    isActual: item?.isActual,
    fromPlaceId: item?.fromPlaceId,
    toPlaceId: item?.toPlaceId

  });

export const createTripListFromRowData = (
  responseTrip: Array<tripResponseModel>,
  bagges: Array<any>
): Array<TripModel> =>
  responseTrip.map((item: tripResponseModel) => createTripFromRowData(item, bagges));

export const createSortedTripListFromRowData = (
  responseTrip: Array<tripResponseModel>,
  bagges: Array<any>
): Array<TripModel> =>
  responseTrip
  .sort((a, b) => {
    const x = moment(a.createdOnTS);
    const y = moment(b.createdOnTS);
    return y.diff(x);
  })
    .map((item: tripResponseModel) => createTripFromRowData(item, bagges))
   ;
