class TripModel {
  readonly tripTitle: string;
  readonly createdOn: string;
  readonly id: number;
  readonly fio: string;
  readonly phoneNumber: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly addressFrom: string;
  readonly addressTo: string;
  readonly transportTypes: Array<string>;
  readonly sum: string;
  readonly baggageType: Array<string>;
  readonly tripStatus: string;
  readonly tripType: string;
  readonly offerSum: string;
  readonly offerFio: string;
  readonly payment: string;
  readonly unvisable: string;
  readonly phoneNumberOffer: string;
  readonly isActual: boolean;
  readonly fromPlaceId: string;
  readonly toPlaceId: string;

  constructor({
    tripTitle,
    createdOn,
    id,
    fio,
    phoneNumber,
    startDate,
    endDate,
    addressFrom,
    addressTo,
    transportTypes,
    sum,
    baggageType,
    tripStatus,
    tripType,
    offerSum,
    offerFio,
    payment,
    unvisable,
    phoneNumberOffer,
    isActual,
    fromPlaceId,
    toPlaceId
  }) {
    this.tripTitle = tripTitle;
    this.createdOn = createdOn;
    this.id = id;
    this.fio = fio;
    this.phoneNumber = phoneNumber;
    this.startDate = startDate;
    this.endDate = endDate;
    this.addressFrom = addressFrom;
    this.addressTo = addressTo;
    this.transportTypes = transportTypes;
    this.sum = sum;
    this.baggageType = baggageType;
    this.tripStatus = tripStatus;
    this.tripType = tripType;
    this.offerSum = offerSum;
    this.offerFio = offerFio;
    this.payment = payment;
    this.unvisable = unvisable;
    this.phoneNumberOffer = phoneNumberOffer;
    this.isActual = isActual;
    this.fromPlaceId = fromPlaceId;
    this.toPlaceId = toPlaceId;
  }
}

export default TripModel
