import {
  Commute,
  PersonAdd,
  ShoppingCart,
  Work,
  Comment,
  DirectionsCar,
  RecentActors,
  VerifiedUser,
  AccountBalanceWallet,
  Streetview,
  Assignment,
  Notifications
} from '@material-ui/icons';

export const NAV_ITEMS = (t:any)=> [
  {
    to: '/user',
    key: t('users'),
    name: t('work_from_users'),
    navIcon: PersonAdd,
    linkType: true
  },
  {
    to: '/trip',
    key: t('trips'),
    name: t('work_from_trip'),
    navIcon: Commute,
    linkType: true
  },
  {
    to: '/parcel',
    key: t('parcels'),
    name: t('work_from_parcel'),
    navIcon: ShoppingCart,
    linkType: true
  },
  {
  	to: '/popularCities',
  	key: t('popular_cities'),
  	name: t('popular_cities'),
  	navIcon: Work,
  	linkType: true
  },
  {
  	to: '/reviews',
  	key: t('reviews'),
  	name: t('reviews'),
  	navIcon: Comment,
  	linkType: true
  },
  {
  	to: '/cars',
  	key: t('cars'),
  	name: t('cars'),
  	navIcon: DirectionsCar,
  	linkType: true
  },
  {
  	to: '/blackList',
  	key: t('black_list'),
  	name: t('black_list'),
  	navIcon: RecentActors,
  	linkType: true
  },
  {
  	to: '/verificationRequests',
  	key: t('verification_requests'),
  	name: t('verification_requests'),
  	navIcon: VerifiedUser,
  	linkType: true
  },
  {
    to: '/parcel/payouts',
    key: t('payouts'),
    name: t('payouts'),
    navIcon: AccountBalanceWallet,
    linkType: true
  },
  {
  	to: '/map',
  	key: t('map'),
  	name: t('map'),
  	navIcon: Streetview,
  	linkType: true
  },
  {
    to: '/deal',
    key: t('deals'),
    name: t('deals'),
    navIcon: Assignment,
    linkType: true
  },
  {
    to: '/notifications',
    key: t('notifications'),
    name: t('notifications'),
    navIcon: Notifications,
    linkType: true
  },
];

export const NAV_DEFAULT_ITEMS = [
  // {
  //   to: '/report',
  //   key: 'Выгрузить отчет',
  //   name: 'Выгрузить отчет',
  //   navIcon: WorkOutline,
  //   linkType: true
  // },
  // {
  //   to: '/chart',
  //   key: 'Графики звонков',
  //   name: 'Графики звонков',
  //   navIcon: ShowChart,
  //   linkType: true
  // }
];
