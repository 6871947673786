import { getDeliveryStatusName } from 'components/ParcelComponent/utils';
import moment from 'moment';
import { returnBaggageType } from '../../models/util';

export const COLUMN = (t: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  }
];
export const labels = [
  'Телефонный номер',
  'Фамилия',
  'Имя пользователя',
  'Отчество',
  'Дата рождения',
  'Эл. адрес',
  'Дата регистрации'
];

export const createParcelDetail = (
  detail: any,
  baggages: any,
  t: any
): { name: string; data: any }[] => {
  return [
    {
      name: t('title'),
      data: detail.parcelTitle
    },
    {
      name: t('date_of_creation'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('address_from'),
      data: detail.addressFrom
    },
    {
      name: t('address_to'),
      data: detail.addressTo
    },
    {
      name: t('user_id'),
      data: detail.id
    },
    {
      name: t('fio_user'),
      data: `${detail?.secondName ?? ''} ${detail?.firstName ?? ''} ${detail?.thirdName ?? ''}`
    },
    {
      name: t('number_user'),
      data: detail.phoneNumber
    },
    {
      name: t('offer_date'),
      data: moment(detail.departureDateTimeTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('sum'),
      data: `${detail.price??''} ${detail.currency??''}`
    },
    {
      name: t('type_baggage'),
      data: detail?.baggageTypes?.map(
        (item: string, index: number) =>
          returnBaggageType(item, baggages, index) +
          (detail.baggageTypes?.length - 1 === index ? '' : ', ')
      )
    },
    {
      name: t('description'),
      data: detail.parcelDescription
    },
    {
      name: t('grade'),
      data: detail.isRated ? t('yes') : t('no')
    },
    {
      name: t('new_statuses'),
      data: getDeliveryStatusName(detail.deliveryStatus, t)
    }
  ];
};
export const statuses = (t: any) => {
  return [
    {
      id: 0,
      title: t('default_status')
    },
    {
      id: 1,
      title: t('sent_status')
    },
    {
      id: 2,
      title: t('noSent_status')
    },
    {
      id: 3,
      title: t('took_status')
    },
    {
      id: 4,
      title: t('noTook_status')
    },
    {
      id: 5,
      title: t('delivered_status')
    },
    {
      id: 6,
      title: t('notDelivered_status')
    },
    {
      id: 7,
      title: t('received_status')
    },
    {
      id: 8,
      title: t('notReceived_status')
    }
  ];
};
export const getIdStatus = (text: string, t: any) => {
  switch (text) {
    case t('default_status'):
      return 0;
    case t('sent_status'):
      return 1;
    case t('noSent_status'):
      return 2;
    case t('took_status'):
      return 3;
    case t('noTook_status'):
      return 4;
    case t('delivered_status'):
      return 5;
    case t('notDelivered_status'):
      return 6;
    case t('received_status'):
      return 7;
    case t('notReceived_status'):
      return 8;
  }
};
export const COLUMN_SHIFTER: any = (t: any): any => {
  return [
    {
      label: t('fio_carrier'),
      name: 'shifterFullName'
    },
    {
      label: t('id_travel'),
      name: 'id',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ cursor: 'pointer' }}>
              <p
                onClick={() => {
                  window.open(`/deal/details/${value}`, '_blank');
                }}>
                {value}
              </p>
            </div>
          );
        }
      }
    },
    {
      label: t('id_carrier'),
      name: 'shifterId',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ cursor: 'pointer' }}>
              <p
                onClick={() => {
                  window.open(`/user/details/${value}`, '_blank');
                }}>
                {value}
              </p>
            </div>
          );
        }
      }
    }
  ];
};
export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
export const COMLUMNOFFERS = (t?: any) => [
  {
    name: 'fullUserName',
    label: t('fio')
  },
  {
    name: 'description',
    label: t('description')
  },
  {
    name: 'offerStatus',
    label: t('offer_status')
  },
  {
    name: 'offerSum',
    label: t('sum')
  },
  {
    name: 'payment',
    label: t('payment')
  },
  {
    name: 'phoneNumber',
    label: t('number_phone')
  }
];
export const createOffers = (offers: any, t: any) => {
  return offers.map((offer) => {
    return {
      fullUserName: offer?.clientInfoModel?.fullUserName,
      description: offer.description,
      offerStatus: offer.offerStatus,
      offerSum: `${offer.offerSum} ${offer.currency}`,
      payment:
        offer?.paymentStatus &&
        offer?.paymentStatus.includes('Paid')
          ? t('done')
          : t('not_done'),
      phoneNumber: offer.phoneNumber
    };
  });
};
