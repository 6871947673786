/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import { returnBaggageType } from '../util';
import ParcelModel from '../../models/parcel';
import { BaggageType } from '../../context/AppContext';
import { getDeliveryStatusName } from 'components/ParcelComponent/utils';

type parcelResponseModel = {
  addressFrom: any; // TODO
  addressTo: any; // TODO
  baggageTypes: Array<string>;
  clientAvatarUrl: string;
  clientId: string;
  createdOnTS: string;
  dealType: string;
  departureDateTimeTS: string;
  firstName: string;
  fullUserName: string;
  id: number;
  lastModified: string;
  offerId: string;
  parcelDescription: string;
  price: number;
  phone: string;
  lastName: string;
  middleName: string;
  userName: string;
  isActual: boolean;
  fromPlaceId: string,
  toPlaceId: string,
  deliveryStatus: string,
  parcelTitle: string,
  currency: string

};

const createParcelFromRowData = (
  responseItem: parcelResponseModel,
  baggages: Array<BaggageType>,
  t:any
): ParcelModel =>
  new ParcelModel({
    parcelTitle: responseItem.parcelTitle,
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    date: moment(responseItem?.departureDateTimeTS).format('DD.MM.YYYY HH:mm').toString(),
    id: responseItem.id,
    addressFrom: responseItem.addressFrom?.city,
    addressTo: responseItem.addressTo?.city,
    fio: `${responseItem?.lastName??''} ${responseItem?.firstName??''} ${responseItem?.middleName??''}`,
    phoneNumber: responseItem?.phone,
    baggageTypes: responseItem?.baggageTypes?.map((item: string, index: number) =>
      returnBaggageType(item, baggages, index)
    ),
    parcelSum: responseItem?.price && responseItem?.currency && `${responseItem?.price} ${responseItem.currency}`,
    dealType: responseItem?.dealType,
    isActual: responseItem?.isActual,
    fromPlaceId: responseItem?.fromPlaceId,
    toPlaceId: responseItem?.toPlaceId,
    deliveryStatus: getDeliveryStatusName(responseItem?.deliveryStatus, t)
  });

export const createParcelsListFromRowData = (
  response: Array<parcelResponseModel>,
  baggages: Array<BaggageType>,
  t: any
): Array<ParcelModel> =>
  response.map((item: parcelResponseModel) => createParcelFromRowData(item, baggages, t));

export const createSortedParcelsListFromRowData = (
  response: Array<parcelResponseModel>,
  baggages: Array<BaggageType>,
  t:any
): Array<ParcelModel> =>
  response
    .sort((a, b) => {
      const x = moment(a.createdOnTS);
      const y = moment(b.createdOnTS);
      return y.diff(x);
    })
    .map((item: parcelResponseModel) => createParcelFromRowData(item, baggages, t))
    ;

export default createSortedParcelsListFromRowData;
