/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useHistory } from 'react-router-dom';

const NotificationsComponent: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tabValue = queryParams.get('tab') as string || '1';

  const handleChange = (event: any, newValue: string) => {
    history.push(`?tab=${newValue}`)
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '100%', margin: '30px' }}>
          <TabContext value={tabValue}>
            <Tabs value={tabValue} onChange={handleChange} textColor='primary' indicatorColor='primary'>
              <Tab value='1' label={`${t('parcels')}`} />
              <Tab value='2' label={`${t('trips')}`} />
              <Tab value='3' label={`${t('profiles')}`} />
              <Tab value='4' label={`${t('cars')}`} />
              <Tab value='5' label={`${t('verification')}`} />
              <Tab value='6' label={`${t('offers')}`} />
              <Tab value='7' label={`${t('reviews')}`} />
              <Tab value='8' label={`${t('deals')}`} />
            </Tabs>
            <TabPanel value='1'>
              <></>
            </TabPanel>
            <TabPanel value='2'>
              <></>
            </TabPanel>
            <TabPanel value='3'>
              <></>
            </TabPanel>
            <TabPanel value='4'>
              <></>
            </TabPanel>
            <TabPanel value='5'>
              <></>
            </TabPanel>
            <TabPanel value='6'>
              <></>
            </TabPanel>
            <TabPanel value='7'>
              <></>
            </TabPanel>
            <TabPanel value='8'>
              <></>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NotificationsComponent;
